<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center mb-4">
                    <div class="text-center">
                        <h4 class="mb-3" v-b-modal.keywordRank>{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
						<p v-b-modal.modal-3 variant="link" class="mb-0"><u>수집이 잘 되지 않는 경우 조치방법</u></p>
							<b-modal id="modal-3" size="xl" title="수집이 잘 되지 않는 경우 조치방법" ok-only>
								<p>1. 확장 설치 후 쿠플러스 사이트를 닫았다가 열어서 다시 로그인 후에 키워드 분석 시도하기</p>
								<p>2. 크롬 브라우저 사용하기</p>
								<p>3. 확장앱 중 1688/타오바오/알리바바 이미지서치 앱 삭제</p>
								<p>4. 크롬 브라우저의 계정을 별도의 계정으로 추가해서 쿠플러스만 확장 설치 후 실행하기</p>
                            </b-modal>
                    </div>
                </div>
			</div>
			<div class="col-lg-12">
				<div class="d-flex justify-content-center" style="height:150%">
					<input ref="cursor" type="text" class="form-control w-25 h-100 text-center rounded-pill" placeholder="검색키워드" v-model="targetKeyword" @keyup.enter="searchenter($event)">
				</div>
			</div>
			<div class="col-lg-12 mt-4 mb-5">
				<div class="d-flex justify-content-center align-items-center">
					<button type="button" class="btn btn-primary" @click="search">키워드 분석</button>
					<!-- <div class="custom-control custom-switch custom-control-inline ml-3">
						<input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="related">
						<label class="custom-control-label" for="customSwitch1">연관검색어</label>
					</div> -->
				</div>
			</div>

			<b-modal :id="'keywordRank'" size="xl" title="키워드 대량분석" ok-title="수집하기" cancel-title="취소" @ok="collectKeyword">
				<div class="row"> 
					<div class="col-md-12 mt-2 d-flex align-items-center justify-contents-center">
						<h6>수집할 키워드 순위 (네이버 데이터랩 쇼핑인사이트)</h6>
						<div class="custom-control-inline ml-3 d-flex justify-content-center">
							<input type="number" :min="1" :max="500" class="form-control" placeholder="" aria-label="" v-model="startRank" style="text-align:center;">
							~
							<input type="number" :min="1" :max="500" class="form-control" placeholder="" aria-label="" v-model="endRank" style="text-align:center;">
						</div>
					</div>
				</div>
			</b-modal>



			<div class="col-lg-12 mt-5" v-if="showspinner">
				<div class="custom-control custom-control-inline d-flex justify-content-center">
					<div class="row">
						<div class="col-12">
							<img style="max-width:50px;" :src="require('../../../assets/images/small/loading.gif')">
							<span>{{loadingtext}}... {{loadingrate}}</span>
						</div>
					</div>
				</div>
			</div>
			<card class="col-lg-12" v-if="showlist">
				<div class="p-3">
					<div class="table-responsive rounded mb-3" style="font-size:80%">
					<tableList>
						<template v-slot:header>
								<tr class="ligth">
									<th v-b-tooltip.top title="쿠팡 연관검색어">쿠팡<br>연관키워드</th>

									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 wing 상품비율">쿠팡<br>1p윙상품비율</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 wing 상품수">쿠팡<br>1p윙상품수</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 로켓 상품수">쿠팡<br>1p로켓상품수</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 그로스 상품수">쿠팡<br>1p그로스상품수</th>
									
									<th v-b-tooltip.top title="키워드의 쿠팡 광고비율(%)">쿠팡<br>광고비율</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 광고상품수">쿠팡<br>광고상품수</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 광고구좌수">쿠팡<br>광고구좌수</th>
									
									<!-- <th v-b-tooltip.top title="키워드의 CPC광고 추천단가(원)">쿠팡<br>CPC광고추천단가</th>
									<th v-b-tooltip.top title="키워드의 CPC광고 최대단가(원)">쿠팡<br>CPC광고최대단가</th>
									<th v-b-tooltip.top title="키워드의 CPC광고 최소단가(원)">쿠팡<br>CPC광고최소단가</th> -->
									
									<th v-b-tooltip.top title="키워드의 쿠팡 로켓비율(%)">쿠팡<br>로켓비율</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 로켓상품수">쿠팡<br>로켓상품수</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 전체상품수">쿠팡<br>전체상품수</th>
																		
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 리뷰수 최대값" class="border-right">쿠팡<br>1p최대리뷰수</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 리뷰수 평균값" class="border-right">쿠팡<br>1p평균리뷰수</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 리뷰수 중간값" class="border-right">쿠팡<br>1p중간리뷰수</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 리뷰수 최저값" class="border-right">쿠팡<br>1p최저리뷰수</th>

									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 판매가 최대값" class="border-right">쿠팡<br>1p최대판매가</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 판매가 평균값" class="border-right">쿠팡<br>1p평균판매가</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 판매가 중간값" class="border-right">쿠팡<br>1p중간판매가</th>
									<th v-b-tooltip.top title="키워드의 쿠팡 1페이지 판매가 최저값" class="border-right">쿠팡<br>1p최저판매가</th>

									<th v-b-tooltip.top title="키워드의 네이버 1페이지 판매가 최대값" class="border-right">네이버<br>1p최대판매가</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 판매가 평균값" class="border-right">네이버<br>1p평균판매가</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 판매가 중간값" class="border-right">네이버<br>1p중간판매가</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 판매가 최저값" class="border-right">네이버<br>1p최저판매가</th>
									
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 최대값 : 월 판매수량">네이버<br>1p최대월판매수</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 평균값 : 월 판매수량">네이버<br>1p평균월판매수</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 중간값 : 월 판매수량">네이버<br>1p중간월판매수</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 최저값 : 월 판매수량">네이버<br>1p최저월판매수</th>
									
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 최대값 : 월 판매매출">네이버<br>1p최대월매출</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 평균값 : 월 판매매출">네이버<br>1p평균월매출</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 중간값 : 월 판매매출">네이버<br>1p중간월매출</th>
									<th v-b-tooltip.top title="키워드의 네이버 1페이지 최저값 : 월 판매매출">네이버<br>1p최저월매출</th>
									
									<th class="border-right">Actions</th>
								</tr>
							</template>
						<template v-slot:body>
								<tr v-for="k,i in rowlines" :key="i">
									<td>{{k.targetKeyword}}</td>

									<td>{{k.wingrate}}</td>
									<td>{{k.wing1p}}</td>
									<td>{{k.rocket1p}}</td>
									<td>{{k.growth1p}}</td>

									<td>{{k.adrate}}</td>
									<td>{{k.adqty}}</td>
									<td>{{k.adplace}}</td>

									<!-- <td>{{k.cpc}}</td>
									<td>{{k.cpchigh}}</td>
									<td>{{k.cpclow}}</td> -->

									<td>{{k.rocketrate}}</td>
									<td>{{k.rocketqty ? k.rocketqty.toLocaleString() : k.rocketqty}}</td>
									<td>{{k.totalqty ? k.totalqty.toLocaleString() : k.totalqty}}</td>
									
									<td>{{k.review1phigh ? k.review1phigh.toLocaleString() : k.review1phigh}}</td>
									<td>{{k.review1p ? k.review1p.toLocaleString() : k.review1p}}</td>
									<td>{{k.review1pmiddle ? k.review1pmiddle.toLocaleString() : k.review1pmiddle}}</td>
									<td>{{k.review1plow ? k.review1plow.toLocaleString() : k.review1plow}}</td>
									
									<td>{{k.price1phigh ? k.price1phigh.toLocaleString() : k.price1phigh}}</td>
									<td>{{k.price1p ? k.price1p.toLocaleString() : k.price1p}}</td>
									<td>{{k.price1pmiddle ? k.price1pmiddle.toLocaleString() : k.price1pmiddle}}</td>
									<td>{{k.price1plow ? k.price1plow.toLocaleString() : k.price1plow}}</td>

									<td>{{k.pricen1phigh ? k.pricen1phigh.toLocaleString() : k.pricen1phigh}}</td>
									<td>{{k.pricen1p ? k.pricen1p.toLocaleString() : k.pricen1p}}</td>
									<td>{{k.pricen1pmiddle ? k.pricen1pmiddle.toLocaleString() : k.pricen1pmiddle}}</td>
									<td>{{k.pricen1plow ? k.pricen1plow.toLocaleString() : k.pricen1plow}}</td>

									<td>{{k.n1psalesqtyhigh ? k.n1psalesqtyhigh.toLocaleString() : k.n1psalesqtyhigh}}</td>
									<td>{{k.n1psalesqty ? k.n1psalesqty.toLocaleString() : k.n1psalesqty}}</td>
									<td>{{k.n1psalesqtymiddle ? k.n1psalesqtymiddle.toLocaleString() : k.n1psalesqtymiddle}}</td>
									<td>{{k.n1psalesqtylow ? k.n1psalesqtylow.toLocaleString() : k.n1psalesqtylow}}</td>
									
									<td>{{k.n1psaleshigh ? k.n1psaleshigh.toLocaleString() : k.n1psaleshigh}}</td>
									<td>{{k.n1psales ? k.n1psales.toLocaleString() : k.n1psales}}</td>
									<td>{{k.n1psalesmiddle ? k.n1psalesmiddle.toLocaleString() : k.n1psalesmiddle}}</td>
									<td>{{k.n1psaleslow ? k.n1psaleslow.toLocaleString() : k.n1psaleslow}}</td>
									
									<!-- <td>{{k.nrate}}</td> -->
									<td>
										<div>
											<!-- <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset"
												href="javascript:void(0)" @click="resetitem(i)"><i class="ri-pencil-line mr-0"></i></a>													 -->
											<a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
												href="javascript:void(0)" @click="deleteitem(i)"><i class="ri-delete-bin-line mr-0"></i></a>
										</div>
									</td>
								</tr>
							</template>
					</tableList>
					</div> 
					<button type="button" class="btn btn-primary mr-2 mb-4" @click="resetitems">전체삭제</button> 
					<button type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button>       
					<!-- <button type="button" class="btn btn-secondary mr-2 mb-4" @click="settinglist">목록설정</button>                       -->
					
				</div>
			</card>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
// import { chrome } from 'vue-chrome-extension';
export default {
	name:'rank',
	data(){
		return{
			related:false,
			auth:'',
			loadingtext:'연관검색어 분석 중',
			loadingrate:'',

			showspinner:false,
			showlist:false,

			waitresult:false,
			targetKeyword:'',
			fullsearch:false,
			addlines: 10,
			rowlines:[],

			title:"키워드 분석",
			desc1:"키워드 별 쿠팡 / 네이버의 리뷰/판매 현황을 분석합니다.",
			desc2:"키워드를 분석할 때마다 결과가 아래로 쌓입니다.",
			desc3:"쿠팡wing에 로그인한 경우 CPC값이 출력됩니다.",
			startRank : 1,
			endRank:500,
		}
	},	
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
	mounted(){
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'login'){
				alert('쿠팡wing에 로그인 해주세요')
				this.showspinner = false;
				// this.showlist = false;
				return
			}
			if(event.data.res && event.data.res === 'collectKeyword'){
				if(event.data.keywords && event.data.keywords.length > 0){
					if(confirm(event.data.keywords.length + '개의 키워드를 분석하시겠습니까?')){
						var keywords = event.data.keywords;
						for(var i=0;i<keywords.length;i++){
							this.rowlines.push({
								targetKeyword:keywords[i],
								wing1p:'',
								rocket1p:'',
								growth1p:'',
								adrate:'',
								adqty:'',
								adplace:'',
								cpc:'',
								cpchigh:'',
								cpclow:'',
								rocketrate:'',
								rocketqty:'',
								totalqty:'',
								crate:'',
								review1p:'',
								review1phigh:'',
								review1plow:'',
								review1pmiddle:'',
								n1psalesqty:'',
								n1psalesqtyhigh:'',
								n1psalesqtylow:'',
								n1psalesqtymiddle:'',
								n1psales:'',
								n1psaleshigh:'',
								n1psaleslow:'',
								n1psalesmiddle:'',	
												
								nrate:'',
								pricen1phigh:'',
								pricen1plow:'',
								pricen1p:'',
								pricen1pmiddle:'',
								price1phigh:'',
								price1plow:'',
								price1p:'',
								price1pmiddle:'',
								wingrate:'',
							})
						}
						this.importresults(keywords)
					} else {
						this.showspinner = false;
					}
				}
				// this.showspinner = false;
				// this.showlist = false;
				return
			}
			if(event.data.res && event.data.res === 'datalabKeywordStatus'){
                console.log(event.data.status);
				this.loadingtext = event.data.text;
				this.loadingrate = '(' + (event.data.status*100).toFixed(0) + "%)";
				this.showspinner = true;
            }
			if(event.data.res && event.data.res === 'keywordanalysisstatus'){
                console.log(event.data.status);
				this.loadingtext = '키워드 별 페이지 분석 중';
				this.loadingrate = '(' + (event.data.status*100).toFixed(0) + "%)";
				this.showspinner = true;
            }
            if(event.data.res && event.data.res === 'keywordanalysis'){
				if(!event.data.cpc){
					alert('CPC단가는 쿠팡wing에 로그인 후 정상적으로 출력됩니다.')
				}
                console.log(event.data.coupang);
				console.log(event.data.naver);
				console.log(event.data.cpc);
				
				this.loadingtext = '데이터 계산 중';
				this.loadingrate = "";
				this.showspinner = true;
				this.showlist = false;
				this.makerowlines(event.data.coupang,event.data.naver,event.data.cpc);
				// var obj = event.data.result;
				// this.rowlines[obj.index].rank = obj.rank;
				// this.rowlines[obj.index].adqty = obj.adqty;
				// this.rowlines[obj.index].adplace = obj.adplace;
				// this.rowlines[obj.index].productqty = obj.productqty;
				// this.rowlines[obj.index].rocketqty = obj.rocketqty;
				// this.rowlines[obj.index].totalrank = obj.totalrank;
				// this.waitresult = false;
				// await this.sleep(10);
				// this.showspinner = false;
				// this.showlist = true;
            }
            if(event.data.res && event.data.res === 'relatedkeywords'){
                console.log(event.data.relatedkeywords)
				var arr = event.data.relatedkeywords;
				var ar = [];
				if(this.related){
					ar = [this.targetKeyword]
				}
				// console.log(arr)
				arr.forEach(ele => {
					if(!ar.includes(ele)){
						ar.push(ele)
					}
				})
				keywords = [];
				// console.log(ar)
				ar.forEach(ele => {
					if(!this.rowlines.find(element => element.targetKeyword == ele)){
						keywords.push(ele);
					}
				})
				// console.log(keywords)
				// this.rowlines = [];
				var cnt = 0
				for(i=0;i<keywords.length;i++){
					cnt++
					this.rowlines.push({
						targetKeyword:keywords[i],
						wing1p:'',
						rocket1p:'',
						growth1p:'',
						adrate:'',
						adqty:'',
						adplace:'',
						cpc:'',
						cpchigh:'',
						cpclow:'',
						rocketrate:'',
						rocketqty:'',
						totalqty:'',
						crate:'',
						review1p:'',
						review1phigh:'',
						review1plow:'',
						review1pmiddle:'',
						n1psalesqty:'',
						n1psalesqtyhigh:'',
						n1psalesqtylow:'',
						n1psalesqtymiddle:'',
						n1psales:'',
						n1psaleshigh:'',
						n1psaleslow:'',
						n1psalesmiddle:'',	
										
						nrate:'',
						pricen1phigh:'',
						pricen1plow:'',
						pricen1p:'',
						pricen1pmiddle:'',
						price1phigh:'',
						price1plow:'',
						price1p:'',
						price1pmiddle:'',
						wingrate:'',
					})
					
				}
				if(cnt == keywords.length){
					this.importresults(keywords)
				}
            }	
        }
		window.addEventListener('message', this.messageEventHandler);

		console.log(navigator)
		this.getauthNo()
		this.startfocus();
	},
	methods: {
		async collectKeyword(){
			window.postMessage({greeting: "collectKeyword",startRank:this.startRank,endRank:this.endRank},"*",)
		},
		async getauthNo(){
			axios.post('/api/getAuthNo')
			.then(res => {
				this.auth = res.data.auth;
			}).catch(console.log)
		},
		resetitems(){
			this.rowlines = [];
		},
		searchenter(event){
			if(event.keyCode === 13){
				this.search();
			}
		},
		startfocus(){
			this.$refs.cursor.focus();
		},
		middlelevel(arr) {
			arr.sort((a,b) => a-b);
			var val = parseInt(arr.length/2);
			return arr[val];
		},
		async makerowlines(coupang,naver,cpc){
			var cpcobj = cpc;
			console.log(coupang)
			console.log(naver)
			console.log(cpc)
			this.rowlines.forEach(e => {
				// console.log(coupang)
				// console.log(e)
				if(coupang.find(ele => ele.keyword == e.targetKeyword)){
					var coupang1p = coupang.find(ele => ele.keyword == e.targetKeyword);
					var coupang1plist = coupang1p.productlists1p.filter(ele => ele.ad == false);
					// console.log(coupang1plist.map(ele => ele.ratingcount))
					var naver1p = naver.find(ele => ele.keyword == e.targetKeyword).keywordvalue;
					var naverlist = naver.find(ele => ele.keyword == e.targetKeyword).products;
					// console.log(naverlist.filter(ele => ele.purchaseCnt >= 0))
					for(var j=0;j<naver1p.length;j++){
						naver1p[j].long = parseInt(naver1p[j].long > naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt/26 ? naver1p[j].long : naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt/26);
						
					}

					var cpc = cpcobj ? cpcobj[e.targetKeyword] : '';
					
					e.wing1p = coupang1plist.filter(ele => ele.type == 'wing').length;
					e.rocket1p = coupang1plist.filter(ele => ele.type == 'rocket').length;
					e.growth1p = coupang1plist.filter(ele => ele.type == 'jet').length;
					e.wingrate = (e.wing1p/(e.wing1p+e.rocket1p+e.growth1p)*100).toFixed(2);
					e.adqty = coupang1p.adqty;
					e.adplace = coupang1p.adplace;
					e.adrate = (coupang1p.adqty/coupang1p.adplace*100).toFixed(2);
					e.cpchigh = cpc ? cpc.high : 0;
					e.cpclow = cpc ? cpc.low : 0;
					e.cpc = cpc ? cpc.suggested : 0;
					e.rocketqty = coupang1p.rocketqty;
					e.totalqty = coupang1p.totalqty;
					e.rocketrate = (coupang1p.rocketqty/coupang1p.totalqty*100).toFixed(2);

					e.review1phigh = coupang1plist.reduce((pv,cv)=>{return pv > cv.ratingcount ? pv : cv.ratingcount},0);
					e.review1plow = coupang1plist.reduce((pv,cv)=>{return pv < cv.ratingcount ? pv : cv.ratingcount},100000000);
					e.review1p = Math.round(coupang1plist.reduce((pv,cv)=>{return pv + cv.ratingcount},0)/coupang1plist.length);
					e.review1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.ratingcount));

					e.price1phigh = coupang1plist.reduce((pv,cv)=>{return pv > cv.price ? pv : cv.price},0);
					e.price1plow = coupang1plist.reduce((pv,cv)=>{return pv < cv.price ? pv : cv.price},100000000);
					e.price1p = Math.round(coupang1plist.reduce((pv,cv)=>{return pv + cv.price},0)/coupang1plist.length);
					e.price1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.price));

					e.pricen1phigh = naver1p.reduce((pv,cv)=>{return pv > cv.price ? pv : cv.price},0);
					e.pricen1plow = naver1p.reduce((pv,cv)=>{return pv < cv.price ? pv : cv.price},100000000);
					e.pricen1p = Math.round((naver1p.reduce((pv,cv)=>{return pv + cv.price},0))/naver1p.length);
					e.pricen1pmiddle = this.middlelevel(naver1p.map(ele => ele.price));
					// e.crate = (e.review1p/e.totalqty).toFixed(2);
					e.n1psalesqtyhigh = naver1p.reduce((pv,cv)=>{return pv > cv.long ? pv : cv.long},0);
					e.n1psalesqtylow = naver1p.reduce((pv,cv)=>{return pv < cv.long ? pv : cv.long},100000000);
					e.n1psalesqty = Math.round((naver1p.reduce((pv,cv)=>{return pv + cv.long},0))/naver1p.length);
					e.n1psalesqtymiddle = this.middlelevel(naver1p.map(ele => ele.long));

					e.n1psaleshigh = naver1p.reduce((pv,cv)=>{return pv > cv.long*cv.price ? pv : cv.long*cv.price},0);
					e.n1psaleslow = naver1p.reduce((pv,cv)=>{return pv < cv.long*cv.price ? pv : cv.long*cv.price},100000000);
					e.n1psales = Math.round((naver1p.reduce((pv,cv)=>{return pv + cv.long*cv.price},0))/naver1p.length);
					e.n1psalesmiddle = this.middlelevel(naver1p.map(ele => ele.long*ele.price));
					
				}
			})
			setTimeout(()=>{
				this.loadingtext = '키워드 별 CPC단가 분석 중';
				this.loadingrate = '';
				this.showspinner = false;
				this.showlist = true;
			},1000)


		},
		async sleep(ms) {
			return new Promise((r) => setTimeout(r, ms));
		},
		async importresults(arr){
			await this.sleep(1000);
			this.loadingtext = '키워드 별 CPC단가 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			// this.showlist = false;
			window.postMessage({greeting: "keywordanalysis",data:arr,auth:this.auth},"*",)
			// // this.waitresult = false;
			// for(var i=0;i<this.rowlines.length;i++){
			// 	var e = this.rowlines[i];
			// 	await this.sleep(1000)
			// 	if(e.targetKeyword || e.productName || e.itemId || e.productId || e.vendorItemId){
			// 		e.showspinner = true;
			// 		e.rank = [];
			// 		e.adqty = '';
			// 		e.adplace = '';
			// 		e.productqty = '';
			// 		e.rocketqty = '';
			// 		e.totalrank = [];
			// 		var data = {
			// 			targetKeyword : e.targetKeyword,
			// 			productName : e.productName,
			// 			itemId : e.itemId,
			// 			productId : e.productId,
			// 			vendorItemId : e.vendorItemId,
			// 			index : i,
			// 			fullsearch : false,
			// 		}
			// 		// console.log(data)
			// 		window.postMessage({greeting: "coupangrank",data:data},"*",)
			// 		// this.waitresult = true;
			// 	}
			// }
			// // this.waitresult = false;
		},
		changelines(){
			this.rowlines = [];
			for(var i=0;i<this.addlines;i++){
				this.rowlines.push({targetKeyword:'',productName:'',itemId:'',productId:'',vendorItemId:'',rank:[],adqty:'',adplace:'',productqty:'',rocketqty:'',showspinner:false,totalrank:[]})
			}
		},
		resetitem(i){
			this.rowlines[i].targetKeyword = '';
			this.rowlines[i].productName = '';
			this.rowlines[i].itemId = '';
			this.rowlines[i].productId = '';
			this.rowlines[i].rank = [];
			this.rowlines[i].adqty = '';
			this.rowlines[i].adplace = '';
			this.rowlines[i].productqty = '';
			this.rowlines[i].rocketqty = '';
			this.rowlines[i].showspinner = false;
			this.rowlines[i].totalrank = [];
		},
		deleteitem(i){
			this.rowlines = this.rowlines.slice(0,i).concat(this.rowlines.slice(i+1))
			if(this.rowlines.length == 0){
				this.showlist = false;
			}
			this.addlines--
		},	
		search(){
			if(!this.targetKeyword){
				alert('분석할 키워드를 입력해 주세요')
				return
			}
			if(!this.related){
				this.rowlines.push({
					targetKeyword:this.targetKeyword,
					wing1p:'',
					rocket1p:'',
					growth1p:'',
					adrate:'',
					adqty:'',
					adplace:'',
					cpc:'',
					cpchigh:'',
					cpclow:'',
					rocketrate:'',
					rocketqty:'',
					totalqty:'',
					crate:'',
					review1p:'',
					review1phigh:'',
					review1plow:'',
					review1pmiddle:'',
					n1psalesqty:'',
					n1psalesqtyhigh:'',
					n1psalesqtylow:'',
					n1psalesqtymiddle:'',
					n1psales:'',
					n1psaleshigh:'',
					n1psaleslow:'',
					n1psalesmiddle:'',						
					nrate:'',
					wingrate:'',
					price1p:'',
					price1phigh:'',
					price1plow:'',
					price1pmiddle:'',
					pricen1p:'',
					pricen1phigh:'',
					pricen1plow:'',
					pricen1pmiddle:'',

				})
				this.importresults([this.targetKeyword])
				return 
			}
			window.postMessage({greeting: "getrelatedkeywords",data:this.targetKeyword,continueAnalysis:true},"*",)

			this.loadingtext = '연관검색어 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			// this.showlist = false;
		},
		searchresult(){
			this.saleslists = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			var 이전재고 = '';
			var t = {};
			var su = {};
			var 입고수량 = 0;
			if(this.$store.state.sales.length > 0){
				this.$store.state.sales.sort(function(a,b){
					if (a.date < b.date){
						return -1;
					}
					if (a.date > b.date){
						return 1;
					}
					return 0;
				})	
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				this.$store.state.productdb.forEach(e => {
					if(this.$store.state.sales.filter(ele => ele.판매세부내역.find(element => element.sk == e["SKU ID"])).length == this.$store.state.sales.length){
						obj = {
							썸네일 : e.썸네일,
							판매상품명 : e.판매상품명,
							SKUID : e["SKU ID"],
							바코드 : e.바코드,
							발주가능상태 : e.발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : e.판매링크,
							판매세부내역 : []
						}
						if(this.$store.state.sales.length > 1){
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								//console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									//console.log(su)
									if(su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).length > 0){
										입고수량 = su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).reduce((pv,rv) => {return pv + parseInt(rv.수량)},0)
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								if(이전재고 == ''){
									이전재고 = t.s ? t.s : 0;
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s+입고수량 ? t.s+입고수량 : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								이전재고 = d.재고 ? d.재고 : 0;
							})
							var salesqty = 0
							for (var j=1;j<obj.판매세부내역.length;j++){
								salesqty = obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 ? obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 : 0;
								obj.판매세부내역[j].판매 = salesqty ? salesqty : 0;
								obj.판매 += salesqty ? salesqty : 0;
								obj.판매세부내역[j].판매매출 = salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매매출 += salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매세부내역[j].공급매출 = salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								obj.공급매출 += salesqty*e.매입가 ? salesqty*e.매입가 : 0;
							}
						} else {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								// console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									// console.log(su)
									if(su.importlist.find(element => element.sku번호.toString() == t.sk.toString())){
										입고수량 = parseInt(su.importlist.find(element => element.sku번호.toString() == t.sk.toString()).수량);
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s ? t.s : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.판매 = 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								obj.판매매출 = 0;
								obj.공급매출 = 0;
								setTimeout(() => {이전재고 = d.재고 ? d.재고 : 0},1)
							})
						}
						this.saleslists.push(this.deepClone(obj))
						this.saleslistsinit.push(this.deepClone(obj))
					}
				})
				
				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}			
			
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		}, 		
		todate(date){
			var today = new Date(date); 
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },		
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},   
		download() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			this.rowlines.forEach(e => {
				arr.push({
					키워드 : e?.targetKeyword,
					쿠팡1p윙비율:e?.wingrate,
					쿠팡1p윙제품수 : e?.wing1p,
					쿠팡1p로켓제품수 : e?.rocket1p,
					쿠팡1p그로스제품수 : e?.growth1p,
					쿠팡광고비율 : e?.adrate,
					쿠팡광고상품수 : e?.adqty,
					쿠팡광고구좌수: e?.adplace,
					쿠팡광고CPC단가_추천: e?.cpc,
					쿠팡광고CPC단가_최대: e?.cpchigh,
					쿠팡광고CPC단가_최저: e?.cpclow,
					쿠팡로켓비율: e?.rocketrate,
					쿠팡로켓상품수: e?.rocketqty,
					쿠팡전체상품수: e?.totalqty,

					쿠팡1p리뷰수_평균: e?.review1p,
					쿠팡1p리뷰수_최대: e?.review1phigh,
					쿠팡1p리뷰수_최저: e?.review1plow,
					쿠팡1p리뷰수_중간: e?.review1pmiddle,

					쿠팡1p판매가_평균: e?.price1p,
					쿠팡1p판매가_최대: e?.price1phigh,
					쿠팡1p판매가_최저: e?.price1plow,
					쿠팡1p판매가_중간: e?.price1pmiddle,

					네이버1p판매가_평균: e?.pricen1p,
					네이버1p판매가_최대: e?.pricen1phigh,
					네이버1p판매가_최저: e?.pricen1plow,
					네이버1p판매가_중간: e?.pricen1pmiddle,

					네이버1p월판매수량_평균: e?.n1psalesqty,
					네이버1p월판매수량_최대: e?.n1psalesqtyhigh,
					네이버1p월판매수량_최저: e?.n1psalesqtylow,
					네이버1p월판매수량_중간: e?.n1psalesqtymiddle,
					네이버1p월매출_평균: e?.n1psales,
					네이버1p월매출_최대: e?.n1psaleshigh,
					네이버1p월매출_최저: e?.n1psaleslow,
					네이버1p월매출_중간: e?.n1psalesmiddle,					
				})
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '키워드분석')
			XLSX.writeFile(workBook, '키워드분석' + '_' + getToday() + '.xlsx')
		},
		settinglist(){
			
		}
	}

}
</script>

<style scoped>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>